import React from 'react'
import { Container, makeStyles, useMediaQuery, useTheme } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  topXS: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  top: {
    paddingTop: theme.spacing(4),
  },
  bottom: {
    paddingBottom: 56,
    ...theme.mixins.toolbar,
  },
}))

const View = ({ maxWidth = 'md', children, ...props }) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.only('xs'))
  const classes = useStyles()

  return (
    <Container className={xs ? classes.topXS : classes.top} component="main" maxWidth={maxWidth} {...props}>
      {children}
      <div className={classes.bottom} />
    </Container>
  )
}

export default View