import { BaseApiService } from './BaseApiService';

class StreetApiService extends BaseApiService {
  constructor(access = '', onError = null) {
    super('/api/street/', access, onError);
  }

  postTicketAction(actionId, values) {
    return this.http.postFetch(`ticket/${actionId}/`, values)
  }

  postTicketAction$(actionId, values) {
    return this.http.postAjax(`ticket/${actionId}/`, values)
  }

}

export default StreetApiService;
