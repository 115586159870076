import React, { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormGroup,
  InputLabel,
  LinearProgress,
  MenuItem,
  Typography,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { put } from '../utils/utils';
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { TextField, Select } from 'formik-material-ui';
import { SESSION_FETCH_ } from '../state/session';
import {
  ResponsiveDialog as Dialog
} from '.';
import {
  useAction,
  useActiveFiatAssets,
  useCountries,
  useError,
  useIntl,
  useLink,
} from '../hooks';
import FormikCheckboxField from './fields/FormikCheckboxField';
import DocumentAgreeDialog from './DocumentAgreeDialog';

const ProfileSettingsDialog = ({ onChange, onCancel, open, profile }) => {
  const translate = useIntl();
  const onError = useError();
  const isPersonal = profile.profileType === 'PERSONAL';
  const defaultDisplayName =
    profile.displayName ||
    (isPersonal && profile.user
      ? `${profile.user.firstName} ${profile.user.lastName}`
      : '');
  const fetchSession = useAction(SESSION_FETCH_);
  const pushLink = useLink();

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>
        {translate.profile.settings_dialog?.title ?? 'Profile Settings'}
      </DialogTitle>
      <Formik
        initialValues={{
          displayName: defaultDisplayName ?? '',
          residency: profile.countryId ?? '234',
          currency: profile.currencyCode ?? 'USD',
          is_visible_to_public: profile.isVisibleToPublic ?? true,
          is_visible_to_anon: profile.isVisibleToAnon ?? true,
        }}
        validationSchema={Yup.object().shape({
          displayName: Yup.string()
            .max(64, translate.profile.field_display_name_error.max_length)
            .required(),
          residency: Yup.number().required(),
          currency: Yup.string().length(3).required(),
          is_visible_to_public: Yup.boolean(),
          is_visible_to_anon: Yup.boolean(),
        })}
        onSubmit={
          // eslint-disable-next-line no-unused-vars
          async ({ link, ...values }, { setSubmitting }) => {
          try {
            const response = await put('/api/ownprofile/', {
              display_name: values.displayName,
              country: values.residency,
              currency_code: values.currency,
              is_visible_to_public: values.is_visible_to_public,
              is_visible_to_anon: values.is_visible_to_anon,
            });
            setSubmitting(false);
            if (response.ok) {
              fetchSession(profile.rowId);
              if (isPersonal && profile.profileCompleteDate === null) {
                //
                // Hijack the dialog flow to show the reward activity
                // and the last profile or venue that was visited to
                // complete the call to action found from whatever 
                // initial view got the user to sign up.
                //
                pushLink('/home')
              }
              else {
                // Otherwise this is not our first rodeo.
                onChange();
              }
            }
            else {
              onError(translate.profile.failed_profile_save_unspecified);
            }
          }
          catch (error) {
            // eslint-disable-next-line no-eval
            onError(eval(`translate.profile.failed_profile_save`));
          }
        }}
        render={
          props => (
            <ProfileForm
              {...props}
              onCancel={onCancel}
              profile={profile}
            />
          )
        }
      />
    </Dialog>
  );
};

const ProfileForm = ({
  isValid,
  onCancel,
  profile,
  resetForm,
  submitForm,
  values,
}) => {
  const translate = useIntl();
  const countries = useCountries();
  const fiats = useActiveFiatAssets();
  const [openAgree, setOpenAgree] = useState(profile.profileType === 'PERSONAL' && !profile.profileCompleteDate);

  if (!countries || !fiats) {
    return <LinearProgress />;
  }

  return (
    <>
    <DocumentAgreeDialog
        document_role="reward_agreement"
        open={openAgree}
        onClose={() => null}
        onAgree={() => setOpenAgree(false)}
    />
    <Form>
      <DialogContent>
        <Box pb={1}>
          <Box>
            <Typography variant='caption'>
              {translate.profile.public_information}
            </Typography>
          </Box>
          <Divider />
          <Box p={1} mt={1}>
            <FormControl fullWidth>
              <Field
                name='displayName'
                autoFocus={true}
                component={TextField}
                label={translate.profile.field_display_name}
              />
              <FormHelperText>
                {translate.profile.field_display_name_hint}
              </FormHelperText>
              <ErrorMessage name='displayName' />
            </FormControl>
          </Box>
        </Box>
        <Box py={1}>
          <Box>
            <Typography variant='caption'>
              {translate.profile.private_information}
            </Typography>
          </Box>
          <Divider />
          <Box p={1} mt={1}>
            <FormControl fullWidth>
              <InputLabel htmlFor='residency-label'>
                {translate.profile.field_residency_label}
              </InputLabel>
              <Field
                name='residency'
                component={Select}
                type='text'
                inputProps={{ id: 'residency-label' }}
              >
                {countries.map((option) => (
                  <MenuItem
                    key={option.rowId}
                    value={option.rowId}
                    selected={option.value === values.residency}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Field>
              <FormHelperText>
                {translate.profile.field_residency_hint}
              </FormHelperText>
              <ErrorMessage name='residency' />
            </FormControl>
          </Box>
          <Box p={1}>
            <FormControl fullWidth>
              <InputLabel htmlFor='currency-label'>
                {translate.profile.field_currency_label}
              </InputLabel>
              <Field
                name='currency'
                component={Select}
                type='text'
                inputProps={{ id: 'currency-label' }}
              >
                {fiats.map((option) => (
                  <MenuItem
                    key={option.code}
                    value={option.code}
                    selected={option.value === values.currency}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Field>
              <FormHelperText>
                {translate.profile.field_currency_hint}
              </FormHelperText>
              <ErrorMessage name='currency' />
            </FormControl>
          </Box>
        </Box>
        {
          profile.profileCompleteDate
          &&
          <Box py={1}>
            <Box>
              <Typography variant='caption'>
                {translate.profile.privacy}
              </Typography>
            </Box>
            <Divider />
            <Box p={1} mt={1}>
              <FormControl component='fieldset'>
                <FormGroup>
                  <FormControl>
                    <FormikCheckboxField
                        name="is_visible_to_public"
                        label={translate.profile.isVisibleToPublic.description}
                    />
                  </FormControl>
                  <FormControl>
                    <FormikCheckboxField
                        name="is_visible_to_anon"
                        label={translate.profile.isVisibleToAnon.description}
                    />
                  </FormControl>
                </FormGroup>
              </FormControl>
            </Box>
          </Box>
        }
        <Box p={1} display='flex' flexDirection='row'>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            onClick={submitForm}
            disabled={!isValid}
            >
            {translate.profile.button_save_profile}
          </Button>
          {
            profile.profileCompleteDate === null
            ||
            <Button
            onClick={() => {
              resetForm();
              onCancel();
            }}
            >
              {translate.profile.button_cancel}
            </Button>
          }
        </Box>
          </DialogContent>
    </Form>
    </>
  );
};

export default ProfileSettingsDialog;
