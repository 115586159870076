import React from 'react';
import { DateTime } from 'luxon';
import { Button } from '@material-ui/core';
import OnVisible from 'react-on-visible';
import { ActivityRow } from './ActivityRow';
import { useIntl, useLang } from '../../hooks';

export const Activities = (props) => {
  const { assets, profile, push, stub } = props;
  const translate = useIntl();
  const locale = useLang();

  const _loadMore = () => {
    if (!props.relay.hasMore() || props.relay.isLoading()) {
      return;
    }

    props.relay.loadMore(
      34,
      // fetch next items
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <div>
      {stub &&
        stub.feed.edges
          .reduce(
            (acc, edge) => {
              const time = DateTime.fromISO(edge.node.timestamp).toLocal();
              const date = 10000 * time.year + 100 * time.month + time.day;
              if (acc.date !== date) {
                acc.all.push({
                  key: `date-${edge.node.id}`,
                  time,
                  type: 'time',
                });
              }
              const item = {
                key: edge.node.id,
                notificationId: edge.node.rowId,
                time,
                type: edge.node.verb,
                action:
                  edge.node.action && edge.node.action.model
                    ? edge.node.action.model
                    : null,
                actionId: edge.node.actionId
                  ? parseInt(edge.node.actionId)
                  : null,
                targetId: edge.node.targetId
                  ? parseInt(edge.node.targetId)
                  : null,
                description: edge.node.description,
                unread: edge.node.unread,
                ...(edge.node.data ? JSON.parse(edge.node.data)['data'] : {}),
              };
              acc.date = date;
              acc.all.push(item);
              return acc;
            },
            /* initial values */ {
              date: undefined,
              all: [],
            }
          )
          .all.map((item) => {
            return (
              <ActivityRow
                key={item.key}
                assets={assets}
                profile={profile}
                push={push}
                locale={locale}
              {...item}
            />
          )
        })}
      <OnVisible
        bounce={true}
        onChange={(isVisible) => isVisible && _loadMore()}
      >
        <Button fullWidth onMouseOver={() => _loadMore()}>
          {translate.nav.more}
        </Button>
      </OnVisible>
    </div>
  );
};
