import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import {
  CircularProgress,
  CssBaseline,
  ThemeProvider,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SnackbarProvider } from 'notistack';
import Notifier from '../components/utils/notistack_redux/Notifier.js';
import { AnonFooter, AnonHeader, Centered, Own, Stone } from '../components';
import Router from './Router';
// eslint-disable-next-line no-unused-vars
import AdminRouter from '../views/admin/AdminRouter';
import { make_theme } from './themes';
import useNavDrawer from '../hooks/layout/useNavDrawer.js';
import { BlockchainInfoProvider } from '../components/hoc/withBlockchainInfo';

const SignIn = lazy(() => import('./SignIn'));
const SignUp = lazy(() => import('./SignUp'));

const App = () => {
  const isReady = useSelector(
    (state) => {
      return !state.session.isInitializing && !!state.view.translate;
    }
  );
  const isAuthenticated = useSelector((state) => !!state.session.isAuthorized);
  const theme = useSelector((state) => state.view.theme);

  return (
    <ThemeProvider theme={make_theme(theme)}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Own.Provider>
          <CssBaseline />
          <BlockchainInfoProvider>
            {isReady ? (
              <Stone.Provider>
                <SnackbarProvider maxSnack={2}>
                  <Notifier />
                  <Suspense fallback={<CircularProgress />}>
                    <Switch>
                      <Redirect exact from='/' to='/home' />
                      <Route exact path='/signin' component={SignIn} />
                      <Route exact path='/signup' component={SignUp} />
                      <Route>
                        <div>
                          {isAuthenticated || (
                            <AnonHeader
                              style={{
                                position: 'absolute',
                                left: 0,
                                right: 0,
                                zIndex: 1,
                              }}
                            />
                          )}
                          <Router />
                          {/*<AdminRouter />*/}
                          {isAuthenticated || <AnonFooter />}
                        </div>
                      </Route>
                    </Switch>
                  </Suspense>
                </SnackbarProvider>
              </Stone.Provider>
            ) : (
              <Centered>
                <CircularProgress />
              </Centered>
            )}
          </BlockchainInfoProvider>
        </Own.Provider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default App;
