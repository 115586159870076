import React from 'react';
import { Container, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  offset: {
    paddingBottom: 56,
    ...theme.mixins.toolbar,
  },
}));

const MainContainer = ({ maxWidth = 'lg', children, ...props }) => {
  const classes = useStyles();
  return (
    <>
      <Container component='main' maxWidth={maxWidth} disableGutters {...props}>
        {children}
        {/* prevent bottom app bar from obscuring content on mobile */}
        <div className={classes.offset} />
      </Container>
    </>
  );
};

export default MainContainer;
