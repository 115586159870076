import { BaseApiService } from './BaseApiService';

class LinksApiService extends BaseApiService {
  constructor(access = '', onError = null) {
    super('/api/links/', access, onError);
  }

  getNew$ = (assetId) => {
    return this.http.getAjax(`${assetId}/new_address/`)
  }

  getNew = (assetId) => {
    return this.http.getFetch(`${assetId}/new_address/`)
  }

  getCurrent$ = (assetId) => {
    return this.http.getAjax(`${assetId}/current_address/`)
  }

  getCurrent = (assetId) => {
    return this.http.getFetch(`${assetId}/current_address/`)
  }
}

export default LinksApiService;
