import { ofType } from 'redux-observable';
import {
  interval,
  map,
  of,
  switchMap,
  take,
  catchError,
  delayWhen,
  takeUntil,
  mapTo
} from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { handleError } from './utils';
import setLocale from '../utils/setLocale';
import decode from 'jwt-decode';
import {
  JwtApiService,
  SessionApiService,
} from '../services';

// action types
//
export const SESSION_AUTHENTICATED = 'SESSION:AUTHENTICATED';
export const SESSION_ANONYMOUS = 'SESSION:ANONYMOUS';
export const SESSION_REFRESHED = 'SESSION:REFRESHED';
export const SESSION_TERMINATE = 'SESSION:TERMINATE';
export const SESSION_TERMINATED = 'SESSION:TERMINATED';

export const SESSION_FETCH = 'SESSION:FETCH';
export const SESSION_FETCHED = 'SESSION:FETCHED';

export const SESSION_LINKS_UNUSED_FETCH = 'SESSION:LINKS_UNUSED_FETCH';
export const SESSION_LINKS_UNUSED_FETCHED = 'SESSION:LINKS_UNUSED_FETCHED';

export const SESSION_SET_PROFILE = 'SESSION:SET';

// action creators
//
export const SESSION_AUTHENTICATED_ = (tokens) => ({
  type: SESSION_AUTHENTICATED,
  tokens,
});
export const SESSION_ANONYMOUS_ = () => ({ type: SESSION_ANONYMOUS });
export const SESSION_REFRESHED_ = (tokens) => ({
  type: SESSION_REFRESHED,
  tokens,
});
export const SESSION_TERMINATE_ = () => ({ type: SESSION_TERMINATE });
export const SESSION_TERMINATED_ = () => ({ type: SESSION_TERMINATED });

export const SESSION_FETCH_ = (profile_id) => ({
  type: SESSION_FETCH,
  profile_id,
});
export const SESSION_FETCHED_ = (session) => ({
  type: SESSION_FETCHED,
  session,
});

export const SESSION_SET_PROFILE_ = (profile) => ({
  type: SESSION_SET_PROFILE,
  profile,
});

const hide_jwt = (() => {
  let jwt = null;
  return {
    set: (value) => (jwt = value),
    get: () => jwt,
  };
})();

const INITIAL_STATE = {
  isInitializing: true,
  open: false,
  time: 0,
  logout: false,
  active: false,
  getToken: hide_jwt.get,
  profile: { id: 0 },
};

export const reduce = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
    case SESSION_AUTHENTICATED:
      console.log(`SESSION_AUTHENTICATED ${action.tokens}`);
      hide_jwt.set(action.tokens.access);
      localStorage.setItem('jwt', action.tokens.access);
      //
      // breaks abstraction to communicate to jwtRefresh
      //
      if (action.tokens.refresh) {
        localStorage.setItem('auth-refresh', action.tokens.refresh);
      }
      return {
        ...state,
        access: action.tokens.access,
      };
    case SESSION_REFRESHED:
      console.log(`SESSION_REFRESHED ${action.tokens}`);
      hide_jwt.set(action.tokens.access);
      return {
        ...state,
        access: action.tokens.access,
      };
    case SESSION_ANONYMOUS:
      return {
        ...state,
        isInitializing: false,
      };
    case SESSION_TERMINATED:
      hide_jwt.set('');
      //
      // Self-destruct!
      //
      localStorage.removeItem('auth-refresh');
      sessionStorage.removeItem('auth-profile');
      window.location = '/';
      return INITIAL_STATE;
    case SESSION_FETCHED:
      setLocale(action.session.user?.preference?.language);
      sessionStorage.setItem('auth-profile', action.session.profile.id);
      return {
        ...state,
        ...action.session,
        isInitializing: false,
      };
    case SESSION_SET_PROFILE:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.profile,
        },
      };
  }
};

function determine_host(host) {
  if (host === 'ORIGIN') {
    return window.location.hostname;
  } else {
    return host;
  }
}

function determine_port(port, local_default) {
  if (port === 'ORIGIN') {
    if (window.location.hostname === 'localhost') {
      return local_default;
    } else if (window.location.port === '') {
      return window.location.protocol === 'https:' ? '443' : '80';
    } else {
      return window.location.port;
    }
  } else {
    return port;
  }
}

const bake = (info) => {
  info.mqttHost = determine_host(info.mqttHost);
  info.mqttPort = determine_port(info.mqttPort, 15675);
  info.mdsHost = determine_host(info.mdsHost);
  info.mdsPort = determine_port(info.mdsPort, 8765);
  return info;
};

export const get = (url, access) =>
  ajax.getJSON(url, {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...(access ? { Authorization: `Bearer ${access}` } : {}),
  });

export const post = (url, body, access) =>
  ajax.post(url, body, {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...(access ? { Authorization: `Bearer ${access}` } : {}),
  });

export const put = (url, body, access) =>
  ajax.put(url, body, {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...(access ? { Authorization: `Bearer ${access}` } : {}),
  });

export const patch = (url, body, access) =>
  ajax.patch(url, body, {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...(access ? { Authorization: `Bearer ${access}` } : {}),
  });

const jwtRefresh = (action) => async () => {
  const now = Date.now() / 1000;
  const refresh = localStorage.getItem('auth-refresh');
  if (refresh) {
    try {
      const jwt = decode(refresh);
      if (jwt.exp > now) {
        const JwtApi = new JwtApiService();
        const response = await JwtApi.refresh({ refresh });
        if (response.status === 200) {
          const tokens = response.bodyJson;
          console.log(`jwtRefresh success ${tokens}`);
          return action(tokens);
        }
        else {
          console.log(`jwtRefresh error ${response.status}`);
        }
      }
    } catch (err) {
      console.log(`jwtRefresh error ${err}`);
    }
  }
  return SESSION_ANONYMOUS_();
};

export const epics = [
  // genesis
  //
  (action$) =>
    action$.pipe(take(1), switchMap(jwtRefresh(SESSION_AUTHENTICATED_))), // try to refresh

  // Upon successful authentication fetch fresh session data.
  //
  (action$, state$) =>
    action$.pipe(
      ofType(SESSION_AUTHENTICATED),
      mapTo(
        SESSION_FETCH_(
          parseInt(sessionStorage.getItem('auth-profile')) ||
            state$.value.session.profile_id
        )
      )
    ),

  // Initiate fetch of session data upon interactive authentication,
  // after editing user or profile data requiring refresh of server's
  // latest opnion of session state (e.g. user and profile data),
  // when switching profiles, or when client is aware of changes
  // affecting authorization.
  //
  (action$, state$) =>
    action$.pipe(
      ofType(SESSION_FETCH),
      switchMap(({ profile_id }) =>
        new SessionApiService(state$.value.session.access)
          .post$({ profile_id })
          .pipe(
            map((event) => SESSION_FETCHED_(bake(event.response))),
            catchError(handleError)
          )
      )
    ),

  // sign out
  //
  (action$, state$) =>
    action$.pipe(
      ofType(SESSION_TERMINATE),
      switchMap(() =>
        new SessionApiService(state$.value.session.access).logout$().pipe(
          map(() => SESSION_TERMINATED_()),
          catchError((error) => {
            if (error.status === 401) {
              return of(SESSION_TERMINATED_())
            }
            else {
              handleError(error)
            }
          })
        )
      )
    ),

  // refresh session every 10 seconds
  //
  (action$, state$) =>
    action$.pipe(
      ofType(SESSION_FETCHED),
      switchMap(() => 
        interval(150_000).pipe( // Run every 2.5 minutes
          takeUntil(action$.pipe(ofType(SESSION_TERMINATED))),
          switchMap(() => jwtRefresh(SESSION_REFRESHED_)())
        )
      )
    ),
];
