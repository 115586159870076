import { BaseApiService } from './BaseApiService';

// Methods ending with "$" return an observable using the rxjs/ajax library.
// Plain methods return the response if successful, null if error.

class BaseGraphqlService extends BaseApiService {

  constructor(url_prefix = '/graphql', access = '', onError = null) {
    super(url_prefix, access, onError);
  }

  post$ = (query, variables) => {
    return this.http.postAjax(``, {query: query, variables: variables});
  };

  post = async (query, variables) => {
    return this.http.postFetch(``, {query: query, variables: variables});
  };

}

export default BaseGraphqlService;
